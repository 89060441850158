import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby';
import ImageTextTile from '../image-text-tile';

export default () => {
 return  <StaticQuery
    query={graphql`
      query {
        allDatoCmsAuthor {
          edges {
            node {
              email
              handle
              bioNode {
                childMarkdownRemark {
                  html
                }
              }
              name
              socialMediaHandles {
                service
                accountName
              }
              headshot {
                fluid {
                  srcSet
                  width
                  tracedSVG
                  src
                  sizes
                  height
                  base64
                  aspectRatio
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      console.log('data in authors list: ', data);

      const displayAuthorTiles = authors => authors.map( author => <Link key={author.node.id} to={`/authors/${author.node.handle}`} ><ImageTextTile data={author} /></Link>);

      return (
        <div className="image-text-tile-wrapper">
          { displayAuthorTiles(data.allDatoCmsAuthor.edges) }
        </div>
      );
    }}
  />

}