import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { graphql, Link, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import ImageTextTile from '../components/reusable/image-text-tile';
import BreadcrumbsWrapper from '../components/reusable/BreadcrumbsWrapper';
import AuthorsList from '../components/reusable/authors-list';

import '../components/assets/css/blog-author.scss';

export default ({ pageContext, location, data }) => {

  const { name, email, headshot: { fluid }, bioNode: { childMarkdownRemark: { html} }, socialMediaHandles } = pageContext.node;
  const { crumbs } = pageContext.breadcrumb;

  const customCrumbLabel = location.pathname.toLowerCase().replace('-', ' ');

  const displayArticles = (edges) => {

    console.log('edges: ', edges)

    return edges.map( (articleTile) => {
     return <li key={articleTile.node.id}>
        <Link to={`/${articleTile.node.canonicalCategory.handle}/${articleTile.node.handle}`} >
          <ImageTextTile data={articleTile.node} />
        </Link>
      </li>
    });
  }

  const createMarkup = () => ({ __html: html });

    return (
    <Layout>
      <SEO title={`Essential Espresso Author: ${name}`} description={`Essential Espresso Author: ${name}`} />
      <BreadcrumbsWrapper crumbs={crumbs} crumbLabel={customCrumbLabel} />
      <article className="blog-author">
        <Img fluid={fluid} />
        <div className="content-wrapper">
          <h1>{name}</h1>
          <p className="email">{email}</p>
          <div className="content-p" dangerouslySetInnerHTML={createMarkup(html)} ></div>
        </div>
      </article>
      <section className="blog-author-article-list">
        <header>
          <h2>Written By {name}</h2>
          <p>{data.allDatoCmsArticle.edges.length} Articles</p>
        </header>
        <ul>
          { displayArticles(data.allDatoCmsArticle.edges) }
        </ul>
       </section>
       <section>
        <header><h2>Our Authors</h2></header>
        <p>We’re a team of baristas, coffee roasters, entrepreneurs, and coffee aficionados who want to share our expert advice, product recommendations, and an all-around passion for coffee with folks like you.</p>
        <AuthorsList />
       </section>
    </Layout>
  );

}

export const getAuthoredArticles = graphql`
    query ($handle: String) {
      allDatoCmsArticle(filter: {author: {handle: {eq: $handle}}}) {
        edges {
          node {
            title
            handle
            meta {
              publishedAt(fromNow: true)
            }
            headerImage {
              fluid(maxWidth: 800, imgixParams: { fm: "jpg", auto: "compress" }) { 
                ...GatsbyDatoCmsFluid
              }
            }
            canonicalCategory {
              handle
              title
            }
            author {
              name
            }
          }
        }
      }
    }`;
